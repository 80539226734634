import { MeiliSearch } from 'meilisearch';
import { MeilisearchHit, MeilisearchModel } from '../app/common/enums/MeilisearchIndexType';

export const useMeilisearch = () => {
    const runtimeConfig = useRuntimeConfig();
    const { tenant } = useAuthStore();
    const meilisearchClient = new MeiliSearch({
        host: runtimeConfig.public.meilisearchHost,
        apiKey: tenant?.meiliSearchToken,
    });

    const search = async <TMeilisearchModel extends MeilisearchModel>(params: {
        model: TMeilisearchModel;
        query: string;
        sort?: string[];
        filter?: string;
        limit?: number;
        offset?: number;
        hitsPerPage?: number;
    }) => {
        const response = await meilisearchClient.index<MeilisearchHit[TMeilisearchModel]>(params.model).search(params.query, {
            filter: params.filter,
            limit: params.limit,
            offset: params.offset,
            hitsPerPage: params.hitsPerPage,
            sort: params.sort,
        });
        return response;
    };

    return { search };
};
