import { ModelType } from '../app/base/schemas/BaseSchema';
import { useEvent } from './useEventBus';

export default function (payload: { ModelType: ModelType; uuid: string; event: MouseEvent }) {
    useEvent('common:meilisearch-hit:context-menu:open', {
        modelType: payload.ModelType,
        uuid: payload.uuid,
        event: payload.event,
    });
}
